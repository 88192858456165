<template>
  <v-dialog
    v-model="modalEditScheduleBreakTime"
    fullscreen
    persistent
    @close="setModalEditScheduleBreakTime(false)"
    transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right">
        <v-btn icon depressed @click="setModalEditScheduleBreakTime(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="main-container">
        <div class="main-content">
          <div class="section-schedule-detail">
            <div class="schedule-content-detail">
              <div class="schedule-detail-header">
                <h2>
                  <span><i class="fal fa-alarm-clock"></i></span
                  >{{ $t("scheduleDetail.title") }}
                </h2>
              </div>
              <v-form class="from-schedules">
                <v-row>
                  <v-col cols="12" md="12" class="pt-10">
                    <label class="label-input">{{
                      $t("scheduleDetail.break_time_name")
                    }}</label>
                    <v-text-field
                      dense
                      outlined
                      v-model="title"
                      :hint="`${server_errors.title}`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input">{{
                      $t("scheduleDetail.break_time_in")
                    }}</label>
                    <date-picker
                      class="time--picker"
                      v-model="from"
                      style="width: 100%"
                      type="time"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input">{{
                      $t("scheduleDetail.break_time_out")
                    }}</label>
                    <date-picker
                      class="time--picker"
                      v-model="to"
                      style="width: 100%"
                      type="time"
                    ></date-picker>
                  </v-col>

                  <v-col cols="6" class="mb-10 mt-4">
                    <v-btn
                      class="btn-save-change"
                      :loading="btnLoading"
                      @click="saveChange"
                    >
                      {{ $t("scheduleDetail.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: {
    visible: {
      default: false,
    },
    schedule: {
      default: {},
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      title: "",
      listBreakTime: [],
      modalEditScheduleBreakTime: false,
      btnLoading: false,
      from: "",
      to: "",
      server_errors: {
        title: "",
        from: "",
        to: "",
        period_break_with_schedule_detail_id: "",
      },
    };
  },
  methods: {
    setModalEditScheduleBreakTime(isVisible) {
      this.modalEditScheduleBreakTime = isVisible;
      this.$emit("change", isVisible);
    },
    getFieldText(item) {
      return `${item.name} (${item.check_in_before} - ${item.check_out_after})`;
    },
    formatSchedule() {
      this.start_in = moment(this.schedule.start_in, "HH:mm:ss").toDate();
      this.check_in_before = moment(
        this.schedule.check_in_before,
        "HH:mm:ss"
      ).toDate();
      this.start_out = moment(this.schedule.start_out, "HH:mm:ss").toDate();
      this.check_out_after = moment(
        this.schedule.check_out_after,
        "HH:mm:ss"
      ).toDate();
    },

    saveChange() {
      const item = {
        title: this.title,
        from: moment(this.from).format("HH:mm"),
        to: moment(this.to).format("HH:mm"),
      };

      this.btnLoading = true;
      const checkInSchedule = this.$route.params.schedule_id;

      this.$axios
        .put(
          `company/check-in-schedule/${checkInSchedule}/break-time/${this.schedule.id}`,
          item
        )
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.btnLoading = false;
          }
          this.setModalEditScheduleBreakTime(false);
          this.$emit("success");
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    fetchScheduleBreakTime() {
      const schedule_id = this.$route.params.schedule_id;
      const schedule_break_time_id = this.schedule.id;
      this.isLoading = true;
      this.$axios
        .get(
          `company/check-in-schedule/${schedule_id}/break-time/${schedule_break_time_id}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listBreakTime = res.data.data;
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalEditScheduleBreakTime = val;
    },
    schedule(value) {
      (this.title = value.title),
        (this.from = moment(value.from, "HH:mm:ss").toDate());
      this.to = moment(value.to, "HH:mm:ss").toDate();

      this.fetchScheduleBreakTime();
    },
  },
  created() {
    this.formatSchedule();
  },
};
</script>

<style scoped lang="scss">
.section-schedule-detail {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-content-detail {
    margin: 0;
    border: 0;
    width: 600px;
    height: 800px;
    //  background-color: yellow;
    padding: 20px;
    position: relative;

    .from-schedules {
      width: 100%;
      margin-top: 40px;
    }
  }

  .schedule-detail-header {
    width: 100%;
    height: auto;
    background-color: red;

    h2 {
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: $font-family;
      position: absolute;
      font-size: 30px;
      color: #363636;

      span {
        margin-right: 10px;
      }
    }
  }
}

.text-show-error {
  font-size: 12px;
  color: red;
}
</style>
