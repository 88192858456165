<template>
  <v-dialog
    v-model="modalEditScheduleDetail"
    fullscreen
    persistent
    @close="setModalEditScheduleDetail(false)"
    transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right">
        <v-btn icon depressed @click="setModalEditScheduleDetail(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="main-container">
        <div class="main-content">
          <div class="section-schedule-detail">
            <div class="schedule-content-detail">
              <div class="schedule-detail-header">
                <h2>
                  <span><i class="fal fa-alarm-clock"></i></span
                  >{{ $t("scheduleDetail.title") }}
                </h2>
              </div>
              <v-form class="from-schedules">
                <v-row>
                  <v-col cols="12" md="12" class="pt-10">
                    <label class="label-input">{{
                      $t("scheduleDetail.name")
                    }}</label>
                    <v-text-field
                      dense
                      outlined
                      placeholder="Name.."
                      v-model="schedule.name"
                      :hint="`${server_errors.name}`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input"
                      >{{ $t("scheduleDetail.start_in")
                      }}<span class="text-show-error">{{
                        server_errors.start_in
                      }}</span></label
                    >
                    <date-picker
                      class="time--picker"
                      v-model="start_in"
                      style="width: 100%"
                      type="time"
                      placeholder="Start Check In"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="label-input"
                      >{{ $t("scheduleDetail.checkin")
                      }}<span class="text-show-error">{{
                        server_errors.check_in_before
                      }}</span></label
                    >
                    <date-picker
                      class="time--picker"
                      v-model="check_in_before"
                      style="width: 100%"
                      type="time"
                      placeholder="Check In Before"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6" class="mt-8">
                    <label class="label-input"
                      >{{ $t("scheduleDetail.start_out")
                      }}<span class="text-show-error">{{
                        server_errors.start_out
                      }}</span></label
                    >
                    <date-picker
                      class="time--picker"
                      v-model="start_out"
                      style="width: 100%"
                      type="time"
                      placeholder="Start Cehck Out"
                    ></date-picker>
                  </v-col>
                  <v-col cols="6" md="6" class="mt-8">
                    <label class="label-input"
                      >{{ $t("scheduleDetail.checkout")
                      }}<span class="text-show-error">{{
                        server_errors.check_out_after
                      }}</span></label
                    >
                    <date-picker
                      class="time--picker"
                      v-model="check_out_after"
                      style="width: 100%"
                      type="time"
                      placeholder="Check Out After"
                    ></date-picker>
                  </v-col>
                  <v-col class="mt-5" cols="6" md="6">
                    <label class="label-input">{{
                      $t("scheduleDetail.late")
                    }}</label>
                    <v-text-field
                      type="number"
                      class="input-number"
                      outlined
                      dense
                      placeholder="Late After.."
                      v-model="schedule.late_after"
                      :hint="`${server_errors.late_after}`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="mt-5" cols="6" md="6">
                    <label class="label-input">{{
                      $t("scheduleDetail.absence")
                    }}</label>
                    <v-text-field
                      type="number"
                      class="input-number"
                      outlined
                      dense
                      placeholder="Absence After.."
                      v-model="schedule.absense_after"
                      :hint="`${server_errors.absense_after}`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                      class="label-input"
                      label="ຕັ້ງເວລາພັກ"
                      v-model="check"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col cols="12" v-if="check">
                    <label class="label-input">{{
                      $t("scheduleDetail.break_time")
                    }}</label>
                    <v-select
                      class="center align-center"
                      :label="$t('scheduleDetail.label_select')"
                      dense
                      outlined
                      v-model="schedule.period_break_with_schedule_detail_id"
                      required
                      :items="listBreakDetail"
                      :item-text="getFieldText"
                      item-value="id"
                      :hint="
                        `${server_errors.period_break_with_schedule_detail_id}`
                      "
                      persistent-hint
                    >
                      <!-- @change="addApprove('hr_approve_ot')" -->
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="mb-10">
                    <v-btn
                      class="btn-save-change"
                      :loading="btnLoading"
                      @click="saveChange"
                    >
                      {{ $t("scheduleDetail.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: {
    visible: {
      default: false,
    },
    schedule: {
      default: {},
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
  },
  data() {
    return {
      check: false,
      name: "",
      listBreakDetail: [],
      modalEditScheduleDetail: false,
      btnLoading: false,
      start_in: "",
      check_in_before: "",
      start_out: "",
      check_out_after: "",
      server_errors: {
        name: "",
        start_in: "",
        check_in_before: "",
        start_out: "",
        check_out_after: "",
        late_after: "",
        absense_after: "",
        period_break_with_schedule_detail_id: "",
      },
    };
  },
  methods: {
    setModalEditScheduleDetail(isVisible) {
      this.modalEditScheduleDetail = isVisible;
      this.$emit("change", isVisible);
    },
    getFieldText(item) {
      return `${item.name} (${item.check_in_before} - ${item.check_out_after})`;
    },
    formatSchedule() {
      this.start_in = moment(this.schedule.start_in, "HH:mm:ss").toDate();
      this.check_in_before = moment(
        this.schedule.check_in_before,
        "HH:mm:ss"
      ).toDate();
      this.start_out = moment(this.schedule.start_out, "HH:mm:ss").toDate();
      this.check_out_after = moment(
        this.schedule.check_out_after,
        "HH:mm:ss"
      ).toDate();
    },

    saveChange() {
      const item = {
        period_break_with_schedule_detail_id: this.check
          ? this.schedule.period_break_with_schedule_detail_id
          : null,
        name: this.schedule.name,
        start_in: moment(this.start_in).format("HH:mm:ss"),
        check_in_before: moment(this.check_in_before).format("HH:mm:ss"),
        start_out: moment(this.start_out).format("HH:mm:ss"),
        check_out_after: moment(this.check_out_after).format("HH:mm:ss"),
        late_after: this.schedule.late_after,
        absense_after: this.schedule.absense_after,
      };

      this.btnLoading = true;
      const checkInSchedule = this.$route.params.schedule_id;

      this.$axios
        .put(
          `company/check-in-schedule/${checkInSchedule}/detail/${this.schedule.id}`,
          item
        )
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.btnLoading = false;
          }
          this.setModalEditScheduleDetail(false);
          this.$emit("success");
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    fetchScheduleDetail() {
      const schedule_id = this.$route.params.schedule_id;
      const schedule_detail_id = this.schedule.id;
      this.isLoading = true;
      this.$axios
        .get(
          `company/check-in-schedule/${schedule_id}/break/${schedule_detail_id}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listBreakDetail = res.data.data;

            console.log(this.listBreakDetail);
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalEditScheduleDetail = val;
    },
    schedule(value) {
      this.start_in = moment(value.start_in, "HH:mm:ss").toDate();
      this.check_in_before = moment(value.check_in_before, "HH:mm:ss").toDate();
      this.start_out = moment(value.start_out, "HH:mm:ss").toDate();
      this.check_out_after = moment(value.check_out_after, "HH:mm:ss").toDate();
      if (value.period_break_with_schedule_detail_id) {
        this.check = true;
      }
      this.fetchScheduleDetail();
    },
  },
  created() {
    this.formatSchedule();
  },
};
</script>

<style scoped lang="scss">
.section-schedule-detail {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-content-detail {
    margin: 0;
    border: 0;
    width: 600px;
    height: 800px;
    //  background-color: yellow;
    padding: 20px;
    position: relative;

    .from-schedules {
      width: 100%;
      margin-top: 40px;
    }
  }

  .schedule-detail-header {
    width: 100%;
    height: auto;
    background-color: red;

    h2 {
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: $font-family;
      position: absolute;
      font-size: 30px;
      color: #363636;

      span {
        margin-right: 10px;
      }
    }
  }
}

.text-show-error {
  font-size: 12px;
  color: red;
}
</style>
