<template>
  <div class="main-container">
    <span class="rollback" @click="rollback"
      ><i class="fas fa-arrow-circle-left"></i
    ></span>
    <div class="section-topic">
      <div class="title-header" style="margin: 0 auto; padding-top: 20px">
        <h1>
          <span class="mr-2"><i class="fal fa-alarm-clock"></i></span
          >{{ $t("scheduleDetail.title") }}
        </h1>
      </div>
    </div>
    <div class="section-action">
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("scheduleDetail.create") }}
        </v-btn>
      </div>
      <div class="search-action"></div>
    </div>
    <div class="section-form-content">
      <v-row class="mt-4">
        <v-col
          cols="12"
          md="4"
          lg="4"
          v-for="item in listScheduleDetail"
          :key="item.index"
        >
          <div class="lfont name-title">{{ item.name }}</div>
          <v-card class="elevation-8">
            <v-card-title class="time-line-title">
              <div
                class="time-line-edit"
                style="position: absolute; right: 40px"
              >
                <!-- v-if="item.purpose == 'normal'" -->
                <div class="time-line-edit" @click="onCheckedScan(item)">
                  <i style="font-size: 20px" class="fal fa-id-card-alt"></i>
                </div>
              </div>
              <div class="time-line-edit" @click="OnEdit(item.id)">
                <i class="fal fa-edit"></i>
              </div>
              <div class="show-tooltip">
                <!-- v-if="item.late_after || item.absense_after" -->
                <vs-tooltip color="#8eadf1">
                  <vs-button color="#8eadf1" size="small" flat>
                    <i style="font-size: 16px" class="fal fa-info-circle"></i>
                  </vs-button>
                  <template #tooltip>
                    <div class="content-tooltip">
                      <h4 class="tooltip-header-title">ໝາຍເຫດ</h4>
                      <div class="tooltip-detail">
                        <p>
                          Late After: {{ item.late_after }}
                          <span>ນາທີ</span>
                        </p>
                        <p>
                          Absents After: {{ item.absense_after }}
                          <span>ນາທີ</span>
                        </p>
                      </div>
                    </div>
                  </template>
                </vs-tooltip>
              </div>
            </v-card-title>
            <v-card-text>
              <!-- <div class="mb-3 mt-4 text-center">MON</div> -->
              <v-row class="mt-4">
                <v-col cols="6" class="horizon-line text-center">
                  <p>Check in before</p>
                  <p class="checkin">
                    <span class="clock-icon"
                      ><i class="fal fa-alarm-clock"></i
                    ></span>
                    {{ item.check_in_before }}
                  </p>
                </v-col>
                <v-col cols="6"></v-col>
                <v-col cols="6" class="horizon-line"></v-col>
                <v-col cols="6" class="check-top text-center">
                  <p>Check Out after</p>
                  <p class="checkout">
                    <span class="clock-icon"
                      ><i class="fal fa-alarm-clock"></i
                    ></span>
                    {{ item.check_out_after }}
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-for="itemDes in item.by_day_of_week"
                :key="itemDes.index"
              >
                <v-col cols="12" md="12" lg="12">
                  <v-divider class="mx-4 mt-2 mb-2"></v-divider>
                </v-col>
                <v-col
                  cols="2"
                  md="2"
                  lg="2"
                  class="text-center align-self-center"
                  >{{ itemDes.week_day }}</v-col
                >
                <v-col cols="5" md="5" lg="5" class="text-center">
                  <v-col cols="12" md="12" lg="12">Check In</v-col>
                  <v-col cols="12" md="12" lg="12">{{
                    itemDes.check_in_before
                  }}</v-col>
                </v-col>
                <v-col cols="5" md="5" lg="5" class="text-center">
                  <v-col cols="12" md="12" lg="12">Check Out</v-col>
                  <v-col cols="12" md="12" lg="12">{{
                    itemDes.check_out_after
                  }}</v-col>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="OnDelete(item.id)"
              >
                <i
                  class="fal fa-trash"
                  style="color:red; font-weight: bold; font-size: 20px;"
                ></i>
              </v-btn>
            </v-card-actions>
          </v-card>
          <Loading v-if="isLoading" />

          <Edit
            :schedule="schedule"
            :visible="modalEditScheduleDetail"
            @success="fetchScheduleDetail"
            @change="(val) => (modalEditScheduleDetail = val)"
          />
        </v-col>
      </v-row>
    </div>

    <div class="section-topic">
      <div class="title-header" style="margin: 0 auto; padding-top: 20px">
        <h1>
          <span class="mr-2"><i class="fal fa-stopwatch"></i></span
          >{{ $t("scheduleDetail.title_break_time") }}
        </h1>
      </div>
    </div>

    <div class="section-action">
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreateBreakTime()">
          {{ $t("scheduleDetail.break_time_create") }}
        </v-btn>
      </div>
      <div class="search-action"></div>
    </div>

    <div class="section-form-content">
      <v-row class="mt-4">
        <v-col
          cols="12"
          md="4"
          lg="4"
          v-for="itemBreak in listScheduleBreakTime"
          :key="itemBreak.index"
        >
          <div class="lfont name-title">{{ itemBreak.title }}</div>
          <v-card class="elevation-8">
            <v-card-title class="time-line-title-break">
              <div
                class="time-line-edit"
                style="position: absolute; right: 40px"
              ></div>
              <div
                class="time-line-edit"
                @click="OnEditBreakTime(itemBreak.id)"
              >
                <i class="fal fa-edit"></i>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-4">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  lg="6"
                  xs="6"
                  class="check-top text-center"
                >
                  <p class="mt-6">Start</p>

                  <p class="checkout">
                    <span class="clock-icon"
                      ><i class="fal fa-stopwatch"></i
                    ></span>
                    {{ itemBreak.from }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  lg="6"
                  xs="6"
                  class="check-top text-center"
                >
                  <p class="mt-6">End</p>
                  <p class="checkout">
                    <span class="clock-icon"
                      ><i class="fal fa-stopwatch"></i
                    ></span>
                    {{ itemBreak.to }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="OnDeleteBreakTime(itemBreak.id)"
              >
                <i class="fal fa-trash" style="color:red; font-size: 25px;"></i>
              </v-btn>
            </v-card-actions>
          </v-card>
          <Loading v-if="isLoading" />

          <Update
            :schedule="schedule"
            :visible="modalEditScheduleBreakTime"
            @success="fetchScheduleBreakTime"
            @change="(val) => (modalEditScheduleBreakTime = val)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Edit from "@/components/ScheduleDetail/Edit";
import Update from "@/views/MainCompany/ScheduleDetail/BreakTime/Update";
export default {
  components: {
    Loading,
    Edit,
    Update,
  },

  data() {
    return {
      isLoading: true,
      listScheduleDetail: [],
      listScheduleBreakTime: [],
      schedule_id: "",
      schedule: {},
      modalEditScheduleDetail: false,
      modalEditScheduleBreakTime: false,
      server_errors: {
        id: "",
      },
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchScheduleDetail();
    },
    onCreate() {
      this.$router.push({ name: "scheduleDetail.create" }).catch(() => {});
    },
    onCreateBreakTime() {
      this.$router
        .push({ name: "scheduleDetail.create_break_time" })
        .catch(() => {});
    },
    rollback() {
      this.$router.back();
    },

    filterSchedule(schedule_id) {
      return (
        this.listScheduleDetail.filter((item) => {
          return item.id == schedule_id;
        })[0] || {}
      );
    },
    filterScheduleBreakTime(schedule_id) {
      return (
        this.listScheduleBreakTime.filter((item) => {
          return item.id == schedule_id;
        })[0] || {}
      );
    },
    OnEdit(schedule_id) {
      this.modalEditScheduleDetail = true;
      this.schedule = {
        ...this.filterSchedule(schedule_id),
      };
    },
    OnEditBreakTime(schedule_id) {
      this.modalEditScheduleBreakTime = true;
      this.schedule = {
        ...this.filterScheduleBreakTime(schedule_id),
      };
    },

    OnDelete(schedule_id) {
      const id = this.$route.params.schedule_id;
      this.$axios
        .delete(`company/check-in-schedule/${id}/detail/${schedule_id}`)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_DeleteItem_OnSuccess(
                "top-right",
                "danger",
                3000
              );
            }, 300);
            this.btnLoading = false;
            this.fetchScheduleDetail();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }

          if (error.response.status === 422) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },

    OnDeleteBreakTime(schedule_id) {
      const id = this.$route.params.schedule_id;
      this.$axios
        .delete(`company/check-in-schedule/${id}/break-time/${schedule_id}`)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$notification.OpenNotification_DeleteItem_OnSuccess(
                "top-right",
                "danger",
                3000
              );
            }, 300);
            this.btnLoading = false;
            this.fetchScheduleBreakTime();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }

          if (error.response.status === 422) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },

    fetchScheduleDetail() {
      const id = this.$route.params.schedule_id;
      this.isLoading = true;
      this.$axios.get(`company/check-in-schedule/${id}/detail`).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listScheduleDetail = res.data.data;
        }
      });
    },

    fetchScheduleBreakTime() {
      const id = this.$route.params.schedule_id;
      this.isLoading = true;
      this.$axios
        .get(`company/check-in-schedule/${id}/break-time`)
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listScheduleBreakTime = res.data.data;
          }
        });
    },
    onCheckedScan(item) {
      this.$router.push({
        name: "checkScan.index",
        params: {
          schedule_detail_id: item.id,
        },
      });
    },
    onAddEmployeeToRoster(item) {
      this.$router.push({
        name: "add.employee.index",
        params: {
          schedule_id: item.id,
        },
      });
    },
  },
  created() {
    this.fetchScheduleDetail();
    this.fetchScheduleBreakTime();
  },
};
</script>

<style scoped lang="scss">
.rollback {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  i {
    font-size: 24px;
    background: rgb(106, 187, 245);
    padding: 8px;
    border-radius: 50%;
    color: #ffffff;
  }
}

.time-line-content {
  width: 800px;
  height: auto;
  // background-color: red;
  margin: 0 auto;
}

.time-line-title-break {
  width: 100%;
  height: 20px;
  background-color: #cb1111;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .time-line-edit {
    width: 25px;
    height: 25px;
    //background-color: yellow;
    position: absolute;
    top: 0;
    right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    i {
      color: white;
      cursor: pointer;
      transition: 0.2s;
    }

    i:hover {
      transform: translateY(-8%);
      font-weight: bold;
    }
  }
}

.time-line-title {
  width: 100%;
  height: 20px;
  background-color: #8eadf1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .time-line-edit {
    width: 25px;
    height: 25px;
    //background-color: yellow;
    position: absolute;
    top: 0;
    right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    i {
      color: white;
      cursor: pointer;
      transition: 0.2s;
    }

    i:hover {
      transform: translateY(-8%);
      font-weight: bold;
    }
  }

  .show-tooltip {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 8px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    i {
      color: white;
      cursor: pointer;
    }
  }
}

.time-line-form {
  width: 100%;
  height: 120px;

  // position: absolute;
  //background-color: red;
}

.time-line-delete {
  position: absolute;
  bottom: 4px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    color: #d70f64;
    font-size: 18px;
    transition: ease-in 0.2s;
    z-index: 1;
  }

  i:hover {
    transform: translateY(-8%);
    cursor: pointer;
    font-weight: bold;
  }
}

.time-line-form-content {
  width: 100%;
  height: 120px;
  //background-color: yellow;
  display: flex;

  .time-line-left {
    flex: 1;
    // background-color: #D70F64;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    position: relative;

    .check-in-title {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }

    .icon-check-in {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      position: absolute;
      top: 25px;
      font-size: 24px;
      color: #719aff;
      margin-top: 5px;
      // left: 24px;
    }
  }

  .time-line-right {
    flex: 1;
    // background-color: #D70F64;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    border-left: 2px dashed #719aff;

    .check-in-title {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      position: absolute;
      right: 20px;
    }
    .checkin {
      font-size: 24px !important;
      font-weight: 600;
    }

    .icon-check-in {
      display: flex;
      position: absolute;
      top: 70px;
      font-size: 24px;
      color: #719aff;
      margin-top: 10px;
      right: 12px;
      // left: 24px;
    }

    .clock-icon {
      margin-right: 5px;
      color: #719aff;
      font-size: 22px;
    }
  }
}

.horizon-line {
  border-right: 2px dashed black;
  margin-bottom: 10px;
  .checkin {
    font-size: 24px !important;
    font-weight: 600;

    .clock-icon {
      color: #719aff;
      font-size: 25px !important;
    }
  }
}
.checkout {
  font-size: 24px !important;
  font-weight: 600;

  .clock-icon {
    color: #719aff;
    font-size: 25px !important;
  }
}
.check-top {
  margin-top: -30px;
}
.line {
  border-bottom: 0.5px solid #757575;
  margin-left: 10px;
  margin-right: 10px;
}
.name-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
}

.schedule-title-name {
  font-family: $font-family;
  font-size: 18px;
}

.tooltip-header-title {
  font-family: $font-family;
  font-size: 16px;
}

.tooltip-detail {
  width: 180px;
  height: 50px;
  font-family: $font-family;
  font-size: 1rem;
}
</style>
