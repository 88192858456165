<template>
  <div class="main-container">
    <div class="main-content">
      <div class="section-schedule-detail mt-4">
        <div class="schedule-content-detail">
          <div class="schedule-detail-header">
            <h2>
              <span><i class="fal fa-stopwatch"></i></span
              >{{ $t("scheduleDetail.title_break_time") }}
            </h2>
          </div>
          <v-form class="from-schedules">
            <v-row>
              <v-col cols="12" md="12" class="pt-10">
                <label class="label-input">{{
                  $t("scheduleDetail.break_time_name")
                }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="data.title"
                  :hint="`${server_errors.title}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input">{{
                  $t("scheduleDetail.break_time_in")
                }}</label>
                <date-picker
                  class="time--picker"
                  v-model="data.from"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input">{{
                  $t("scheduleDetail.break_time_out")
                }}</label>
                <date-picker
                  class="time--picker"
                  v-model="data.to"
                  style="width: 100%"
                  type="time"
                ></date-picker>
              </v-col>

              <v-col cols="6" class="mb-3 mt-4">
                <v-btn
                  class="btn-save-change"
                  :loading="btnLoading"
                  @click="saveChange"
                >
                  {{ $t("scheduleDetail.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      check: false,
      break_type: "",
      listBreakDetail: [],
      btnLoading: false,
      data: {
        title: "",
        from: "",
        to: "",
      },
      server_errors: {
        title: "",
        from: "",
        to: "",
        period_break_with_schedule_detail_id: "",
      },
    };
  },
  methods: {
    getFieldText(item) {
      return `${item.name} (${item.check_in_before} - ${item.check_out_after})`;
    },
    saveChange() {
      this.btnLoading = true;
      const checkInSchedule = this.$route.params.schedule_id;
      const item = {
        schedule_id: checkInSchedule,
        title: this.data.title,
        from: moment(this.data.from).format("HH:mm"),
        to: moment(this.data.to).format("HH:mm"),
      };
      this.$axios
        .post(`company/check-in-schedule/${checkInSchedule}/break-time`, item)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$router.push({ name: "scheduleDetail.index" });
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          this.btnLoading = false;
        });
    },
    // fetchScheduleDetail() {
    //   const id = this.$route.params.schedule_id;
    //   this.isLoading = true;
    //   this.$axios.get(`company/check-in-schedule/${id}/break`).then((res) => {
    //     if (res.data.code === 200) {
    //       this.isLoading = false;
    //       this.listBreakDetail = res.data.data;
    //     }
    //   });
    // },
  },
  //   created() {
  //     this.fetchScheduleDetail();
  //   },
};
</script>

<style scoped lang="scss">
.section-schedule-detail {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-content-detail {
    margin: 0;
    border: 0;
    width: 600px;
    height: 800px;
    //  background-color: yellow;
    padding: 20px;
    position: relative;

    .from-schedules {
      width: 100%;
      margin-top: 40px;
    }
  }

  .schedule-detail-header {
    width: 100%;
    height: auto;

    h2 {
      display: flex;
      justify-content: center;
      align-self: center;
      font-family: $font-family;
      position: absolute;

      span {
        margin-right: 10px;
      }
    }
  }
}

.text-show-error {
  font-size: 12px;
  color: red;
}
</style>
